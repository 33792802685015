// src/pages/ProductList.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the search query from the URL
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    if (query) {
      fetch( `/api/search/search?query=${query}`)
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
          setError(error);
          setLoading(false);
        });
    }
  }, [query]);

  const goToDetailPage = (productId) => {
    navigate( `/product/detail/${productId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!products.length) return <div>No products found.</div>;

  return (
      <section className="py-24">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="font-bold text-3xl sm:text-4xl text-black mb-8 text-center">
            Bike
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            {products.map((product) => (
              <div
                key={product.seq} // Use 'seq' from the API response
                className="bg-white cursor-pointer rounded-md overflow-hidden group"
                onClick={() => goToDetailPage(product.seq)}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={product.image300 || '/images/default_image.png'}
                    alt={product.name}
                    className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
                  />
                  <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-indigo-600 absolute bottom-0 right-0">
                    {product.releaseDate || 'Release Date'}
                  </div>
                </div>
                <div className="p-6">
                  <h6 className="font-medium text-xl leading-8 text-black mb-2">
                    {product.name}
                  </h6>
                  <p className="text-gray-700 mb-2">{product.description}</p>
                  <h6 className="font-semibold text-xl leading-8 text-gray-600">
                    {product.price.toLocaleString()}원
                  </h6>
                  <button
                    type="button"
                    className="px-4 py-2 mt-6 rounded-md text-white text-sm tracking-wider bg-indigo-600 hover:bg-indigo-700"
                  >
                    자세히 보기
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
};

export default ProductList;
