import React, { useState, useEffect } from 'react';

const Main = () => {
  const [media, setMedia] = useState([]);
  const [mainBanner, setMainBanner] = useState(null); // State for the main image banner
  const [videoBanner, setVideoBanner] = useState(null); // State for the video banner

  const categories = [
    { name: 'Series', range: [6, 11] },
    { name: 'Collaboration', range: [12, 18] },
    { name: 'Other', range: [19, 23] },
    { name: 'Custom', range: [24, 24] },
  ];

  // Helper function to get a random sequence ID within a given range
  const getRandomSeqInRange = (range) => {
    const [min, max] = range;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Fetch six random items per subcategory and the main banner image
  const fetchMediaData = async () => {
    try {
      // Fetch the video banner for category/1
      const videoResponse = await fetch( '/api/product/category/1 ', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ page: 0, size: 1 }), // Fetch only one item
      }).then((res) => res.json());

      // Filter the item with mainYN === 'Y'for the video banner if available
      const videoBannerItem = videoResponse.find((item) => item.mainYN === 'Y');
      if (videoBannerItem && videoBannerItem.image1300) {
        setVideoBanner(videoBannerItem);
      } else {
        setVideoBanner(null);
      }

      // Fetch random items for each category
      const responses = await Promise.all(
        categories.map((category) =>
          fetch(`/api/product/category/${getRandomSeqInRange(category.range)}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ page: 0, size: 6 }),
          }).then((res) => res.json())
        )
      );

      // Extract items with mainYN === 'Y'for the main image banner
      const mainBannerItem = responses.flat().find((item) => item.mainYN === 'Y');
      setMainBanner(mainBannerItem || null);

      // Group the fetched items for display
      const groupedMedia = categories.map((parentCategory, index) => ({
        category: parentCategory.name,
        items: responses[index].slice(0, 3), // Limit items to first three
      }));

      setMedia(groupedMedia);
    } catch (error) {
      console.error('Error fetching media data:', error);
    }
  };

  useEffect(() => {
    fetchMediaData();
  }, []);

  return (
    <main className="font-sans p-4 mx-auto lg:max-w-5xl md:max-w-3xl sm:max-w-full">
      {/* Video Banner Section */}
      {videoBanner && (
        <section className="relative max-w-2xl mx-auto mb-12">
          <div className="relative w-full aspect-[3/2]">
            <video
              className="w-full h-auto object-cover rounded-lg"
              src={videoBanner.image1300 || '/images/default_video.mp4'} // Use video source or fallback
              autoPlay
              loop
              muted
            >
              {/* Fallback message if video cannot be played */}
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
      )}

      {/* Main Image Banner Section */}
      {mainBanner && (
        <section className="relative max-w-2xl mx-auto mb-12">
          <div className="relative w-full aspect-[3/2]">
            <img
              className="w-full h-auto object-cover rounded-lg"
              src={mainBanner.image1300 || '/images/default_image_1300.png'}
              alt={mainBanner.name}
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <h1 className="text-white text-4xl font-bold bg-black bg-opacity-50 p-4 rounded">
                {mainBanner.name}
              </h1>
            </div>
          </div>
        </section>
      )}

      {/* Products Section */}
      {media.map(({ category, items }) => (
        <div key={category} className="mb-12">
          <h2 className="text-4xl font-extrabold text-gray-800 mb-8">{category}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {items.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded overflow-hidden shadow-md cursor-pointer hover:scale-[1.02] transition-all"
              >
                <div className="w-full aspect-w-16 aspect-h-8 lg:h-80">
                  <img
                    src={item.image400 || '/images/default_image.png'}
                    alt={item.name}
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <div className="p-4">
                  <h4 className="text-lg font-bold text-gray-800">{item.name}</h4>
                   <h6 className="font-semibold text-xl leading-8 text-gray-600">
                                      {item.price.toLocaleString()}원
                                    </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </main>
  );
};

export default Main;
