// src/components/Contact.js
import React, { useState } from 'react';

const Contact = () => {
  // Form data and modal states
  const [formData, setFormData] = useState({
    title: '',
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Input field change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Map formData to match ContactDTO structure
    const contactDTO = {
      name: formData.name,
      email: formData.email,
      title: formData.title,
      contents: formData.message,
      phone: formData.phone,
      codeSeq: 27, // Example placeholder
    };

    try {
      const response = await fetch(`/api/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contactDTO),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setShowFormModal(false); // Close the form modal
        setShowSuccessModal(true); // Show the success modal
      } else {
        console.error('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Modal close handlers
  const closeFormModal = () => setShowFormModal(false);
  const closeSuccessModal = () => setShowSuccessModal(false);

  return (
    <section>
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
        {/* Component */}
        <div className="grid items-center gap-8 sm:gap-20 lg:grid-cols-2 lg:gap-5">
          {/* Left Section */}
          <div>
            <h3 className="mb-6 max-w-2xl text-3xl font-bold md:mb-10 md:text-5xl lg:mb-12">
              어스오브제는 섬세한 손길과 정성이 깃든 귀금속 공방에서 탄생한 주문제작 브랜드입니다.
            </h3>
            {/* List */}
            <ul className="grid max-w-lg gap-4 grid-cols-1 sm:grid-cols-2">
              {/* Item 1 */}
              <li className="flex items-start space-y-2 sm:space-y-0">
                <p className="text-sm sm:text-base leading-relaxed">
                  어스오브제의 상징처럼 마치 건축물의 기둥이 건물 전체를 지탱하듯, 저희의 주얼리는 당신의 개성과 이야기를 담아 빛나는 중심이 되어줄 것입니다.
                </p>
              </li>

              {/* Item 2 */}
              <li className="flex items-start space-y-2 sm:space-y-0">
                <p className="text-sm sm:text-base leading-relaxed">
                  저희는 단순한 오브제를 넘어, 이야기를 담는 특별한 작품을 만듭니다. 오랜 시간 함께하며 변치 않는 가치를 선사하는 주얼리, 어스오브제와 함께 만들어가세요.
                </p>
              </li>

              {/* Item 3 */}
              <li className="flex items-start space-y-2 sm:space-y-0">
                <p className="text-sm sm:text-base leading-relaxed">
                  상담 가능 시간: 10:00-18:00
                </p>
              </li>

              {/* Item 4 */}
              <li className="flex items-start space-y-2 sm:space-y-0">
                <p className="text-sm sm:text-base leading-relaxed">
                  * 주소: 서울특별시 동대문구 서울시립대로 117 104호 USOBJET
                  <br />
                  * 전화: 010-7923-4825
                  <br />
                  * 이메일: usobjet@gmail.com
                  <br />
                  * 인스타그램: us_objet
                </p>
              </li>

              {/* Item 5 */}
              <li className="flex items-start space-y-2 sm:space-y-0 col-span-2 sm:col-span-1">
                <p className="text-sm sm:text-base leading-relaxed">
                  문의 가능한 내용:
                  <br />- 주문 제작 상담
                  <br />- 디자인 문의
                  <br />- 제품 A/S
                  <br />- 기타 문의
                </p>
              </li>
            </ul>

            {/* Divider */}
            <div className="mb-10 mt-10 w-full max-w-lg border-b border-gray-300 "></div>
            <button
              onClick={() => setShowFormModal(true)}
              className="inline-block bg-black px-6 py-3 font-semibold text-white"
            >
              Contact Us
            </button>
          </div>
          {/* Right Section */}
          <div className="grid grid-cols-2 gap-4">
            <img
              src="/images/contactus1.jpeg"
              alt="Contact Us 1"
              className="rounded-lg object-cover w-full h-full"
            />
            <img
              src="/images/contactus2.jpeg"
              alt="Contact Us 2"
              className="rounded-lg object-cover w-full h-full"
            />
            <img
              src="/images/contactus3.jpeg"
              alt="Contact Us 3"
              className="rounded-lg object-cover w-full h-full"
            />
            <img
              src="/images/contactus4.jpeg"
              alt="Contact Us 4"
              className="rounded-lg object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      {/* Form Modal */}
      {showFormModal && (
        <div
          className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-50 bg-opacity-50 bg-gray-800 overflow-auto font-sans"
          onClick={closeFormModal}
        >
          <div
            className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center">
              <h3 className="text-blue-600 text-xl font-bold flex-1">
                문의하기
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
                viewBox="0 0 320.591 320.591"
                onClick={closeFormModal}
              >
                <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
                <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
              </svg>
            </div>

            <form className="space-y-4 mt-8" onSubmit={handleSubmit}>
              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  제목
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="제목을 입력하세요"
                  className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  이름
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="이름을 입력하세요"
                  className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  내용
                </label>
                <textarea
                  name="message"
                  placeholder="문의 내용을 입력하세요"
                  className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  전화번호
                </label>
                <input
                  type="text"
                  name="phone"
                  placeholder="전화번호를 입력하세요"
                  className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label className="text-gray-800 text-sm mb-2 block">
                  이메일
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="이메일을 입력하세요"
                  className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex justify-end gap-4 mt-8">
                <button
                  type="button"
                  onClick={closeFormModal}
                  className="px-6 py-3 rounded-lg text-gray-800 text-sm bg-gray-200 hover:bg-gray-300"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-6 py-3 rounded-lg text-white text-sm bg-blue-600 hover:bg-blue-700"
                >
                  제출
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div
          className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-50 bg-opacity-50 bg-black overflow-auto font-sans"
          onClick={closeSuccessModal}
        >
          <div
            className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3.5 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500 float-right"
              viewBox="0 0 320.591 320.591"
              onClick={closeSuccessModal}
            >
              <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
              <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
            </svg>

            <div className="my-8 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-14 shrink-0 fill-green-500 inline"
                viewBox="0 0 512 512"
              >
                <path d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z" />
                <path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z" />
              </svg>
              <h4 className="text-xl text-gray-800 font-semibold mt-4">
                성공적으로 제출되었습니다!
              </h4>
              <p className="text-sm text-gray-500 leading-relaxed mt-4">
                저희에게 연락해주셔서 감사합니다. 곧 연락드리겠습니다.
              </p>
            </div>

            <button
              type="button"
              onClick={closeSuccessModal}
              className="px-5 py-2.5 w-full rounded-lg text-white text-sm bg-gray-800 hover:bg-gray-700"
            >
              확인
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
