import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const [menus, setMenus] = useState([]);
  const [query, setQuery] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false); // Added state for submenu visibility
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) navigate(`/search?query=${encodeURIComponent(query)}`);
  };

  useEffect(() => {
    fetch(`/api/menus/getMenus`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => setMenus(data.rows))
      .catch((error) => console.error('Error fetching menu data:', error));
  }, []);

  const mainMenus = menus.filter((menu) => menu.parentSeq === 1);
  const getSubMenuItems = (parentSeq) => menus.filter((menu) => menu.parentSeq === parentSeq);

  return (
    <header className="shadow-md bg-white font-[sans-serif] tracking-wide relative z-50 w-full">
      <section className="flex items-center flex-wrap lg:justify-center gap-4 py-2 sm:px-16 px-4 border-gray-200 border-b min-h-[60px]">
        <div className="left-10 absolute z-50 bg-gray-100 flex px-3 py-2 rounded max-lg:hidden">
          <input
            type="text"
            placeholder="Search..."
            className="outline-none bg-transparent w-full text-sm"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit" onClick={handleSearch}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" className="cursor-pointer fill-gray-400 mr-4 rotate-90 inline-block" viewBox="0 0 192.904 192.904">
              <path d="M190.707 180.101l-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z" />
            </svg>
          </button>
        </div>

        <a href="/" className="shrink-0"><img src="/images/logo.png" alt="logo" className="md:w-[100px] w-28 h-auto" /></a>

        <div className="lg:absolute lg:right-10 flex items-center ml-auto space-x-8">

          {/* FAQ and Contact Links */}
          <span className="text-sm text-gray-700 hover:text-blue-500 cursor-pointer">
            <Link to="/faq">FAQ</Link>
          </span>
          <span className="text-sm text-gray-700 hover:text-blue-500 cursor-pointer">
            <Link to="/contact">Contact</Link>
          </span>
          <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="lg:hidden">
            <svg className="w-7 h-7" fill="#000" viewBox="0 0 20 20">
              <path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" />
            </svg>
          </button>
        </div>
      </section>

      {/* Desktop Navigation */}
           <div
             className="hidden lg:block relative"
             onMouseEnter={() => setSubmenuOpen(true)}  // Show submenu on mouse enter
             onMouseLeave={() => setSubmenuOpen(false)} // Hide submenu on mouse leave
           >
             <nav className="flex items-center justify-center px-16 py-2 relative z-10">
               <ul className="flex gap-12">
                 {mainMenus.map((menu) => (
                   <li key={menu.seq} className="relative">
                     <Link to={`/`} className="hover:text-blue-500">
                       {menu.name}
                     </Link>
                   </li>
                 ))}
               </ul>
             </nav>

             {/* Submenu Dropdown */}
             {submenuOpen && (
               <div
                 className="absolute left-0 top-full bg-white shadow-lg rounded p-4 w-full mt-0"
                 onMouseEnter={() => setSubmenuOpen(true)}
                 onMouseLeave={() => setSubmenuOpen(false)}
               >
                 <ul className="flex gap-8 justify-center">
                   {mainMenus.map((menu) => (
                     <li key={menu.seq} className="flex flex-col items-start">
                       <ul>
                         {getSubMenuItems(menu.seq).map((subItem) => (
                           <li key={subItem.seq} className="hover:text-blue-500">
                             <Link to={`/${menu.value}/${subItem.value}`}>{subItem.name}</Link>
                           </li>
                         ))}
                       </ul>
                     </li>
                   ))}
                 </ul>
               </div>
             )}
           </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="lg:hidden bg-white p-4 shadow-md">
          <ul>
            {mainMenus.map((menu) => (
              <li key={menu.seq} className="p-1">
                <Link to={`/${menu.name}`} className="hover:text-blue-500">
                  {menu.name}
                </Link>
                {getSubMenuItems(menu.seq).length > 0 && (
                  <ul className="pl-4 mt-2">
                    {getSubMenuItems(menu.seq).map((subItem) => (
                      <li key={subItem.seq}>
                        <Link to={`/${menu.name}/${subItem.name}`} className="hover:text-blue-500 block">
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
