import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './pages/Main/Main';
import Essential from './pages/Series/Essential';
import Signature from './pages/Series/Signature';
import Bike from './pages/Series/Bike';
import Latch from './pages/Series/Latch';
import Texture from './pages/Series/Texture';
import Pattern from './pages/Series/Pattern';
import WassupCub from './pages/Collaboration/WassupCub';
import RSG from './pages/Collaboration/RSG';
import NSW from './pages/Collaboration/NSW';
import Accelerando from './pages/Collaboration/Accelerando';
import Jangdojang from './pages/Collaboration/Jangdojang';
import Mocampy from './pages/Collaboration/Mocampy';
import Bittersweet from './pages/Collaboration/Bittersweet';
import Objet from './pages/Other/Objet';
import WatchStrap from './pages/Other/WatchStrap';
import KeyRing from './pages/Other/KeyRing';
import SizeGauge from './pages/Other/SizeGauge';
import ETC from './pages/Other/ETC';
import PersonaMade from './pages/Custom/PersonaMade';
import SpecialOrder from './pages/Custom/SpecialOrder';
import Contact from './pages/Contact/Contact';
import FAQ from './pages/Faq/FAQ';
import NotFound from './pages/NotFound';
import ProductDetail from './pages/Product/ProductDetail';  // Import the new ProductDetail component
import ProductList from './pages/Product/ProductList';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* 메인 페이지 */}
        <Route path="/" element={<Home />} />

        {/* Series 페이지 및 하위 메뉴 */}
        <Route path="/series/essential" element={<Essential />} />
        <Route path="/series/signature" element={<Signature />} />
        <Route path="/series/bike" element={<Bike />} />
        <Route path="/series/latch" element={<Latch />} />
        <Route path="/series/texture" element={<Texture />} />
        <Route path="/series/pattern" element={<Pattern />} />
        <Route path="/series/:category/detail/:productId" element={<ProductDetail />} />

        {/* Collaboration 페이지 및 하위 메뉴 */}
        <Route path="/collaboration/wassup-cub" element={<WassupCub />} />
        <Route path="/collaboration/rsg" element={<RSG />} />
        <Route path="/collaboration/nsw" element={<NSW />} />
        <Route path="/collaboration/accelerando" element={<Accelerando />} />
        <Route path="/collaboration/jangdojang" element={<Jangdojang />} />
        <Route path="/collaboration/mocampy" element={<Mocampy />} />
        <Route path="/collaboration/bittersweet" element={<Bittersweet />} />
        <Route path="/collaboration/:category/detail/:productId" element={<ProductDetail />} />

        {/* Other 페이지 및 하위 메뉴 */}
        <Route path="/other/objet" element={<Objet />} />
        <Route path="/other/watch-strap" element={<WatchStrap />} />
        <Route path="/other/key-ring" element={<KeyRing />} />
        <Route path="/other/size-gauge" element={<SizeGauge />} />
        <Route path="/other/etc" element={<ETC />} />
        <Route path="/other/:category/detail/:productId" element={<ProductDetail />} />

        {/* Custom 페이지 및 하위 메뉴 */}
        <Route path="/custom/persona-made" element={<PersonaMade />} />
        <Route path="/custom/persona-made/detail/:productId" element={<ProductDetail />} />
        <Route path="/custom/special-order" element={<SpecialOrder />} />

        {/* Contact 및 FAQ 페이지 */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/search" element={<ProductList />} />
        <Route path="/product/detail/:productId" element={<ProductDetail />} />
        {/* 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
