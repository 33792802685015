// src/components/Custom.js
import React, { useState, useEffect } from 'react';
import './SpecialOrder.css';

const Custom = () => {
  const [selectedImage, setSelectedImage] = useState('/images/default_image_400.png');
  const [formDetails, setFormDetails] = useState({
    type: '유',
    size: '상단',
    shape: '유',
    texture: '다이아몬드',
    metalKind: '금',
  });
  /*
  type	type
  size	size
  shape	shape
  texture	texture
  소재	metal_kind
  */
  const [mainOptions, setMainOptions] = useState([]);
  const [subOptions, setSubOptions] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    name: '',
    phone: '',
  });

  const handleOptionClick = (mainOptionName, value) => {
    setFormDetails({
      ...formDetails,
      [mainOptionName]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOrderSubmit = () => {
    // Open the form modal when "Place Order" is clicked
    setShowFormModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Merge formDetails and formData for submission
    const submissionData = {
      ...formDetails,
      ...formData,
    };
    try {
      const response = await fetch('/api/custom/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        setShowFormModal(false);
        setShowSuccessModal(true);
      } else {
        console.error('Order submission failed');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  const closeFormModal = () => setShowFormModal(false);
  const closeSuccessModal = () => setShowSuccessModal(false);

  const fetchMainOptions = async () => {
    try {
      const response = await fetch('/api/menus/getParentCode/25', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (data.result === 'success') {
        const uniqueMainOptions = Array.from(new Map(data.rows.map((item) => [item.seq, item])).values());
        setMainOptions(uniqueMainOptions);
        uniqueMainOptions.forEach((mainOption) => fetchSubOptions(mainOption.seq));
      }
    } catch (error) {
      console.error('Error fetching main options:', error);
    }
  };

  const fetchSubOptions = async (mainSeq) => {
    try {
      const response = await fetch(`/api/menus/getSelectCode/${mainSeq}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (data.result === 'success') {
        setSubOptions((prev) => ({ ...prev, [mainSeq]: data.rows }));
      }
    } catch (error) {
      console.error('Error fetching sub-options:', error);
    }
  };

  useEffect(() => {
    fetchMainOptions();
  }, []);

  // Separate the mainOption with seq === 48
  const specialMainOption = mainOptions.find((option) => option.seq === 48);
  const otherMainOptions = mainOptions.filter((option) => option.seq !== 48);

  return (
    <section className="flex justify-center">
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24">
        {/* Component */}
        <div className="flex flex-col gap-12">
          {/* Title */}
          <div className="flex flex-col gap-5 text-center">
            <h3 className="text-2xl font-bold md:text-5xl">Special-Order</h3>
            <p className="text-sm text-[#808080] sm:text-base"></p>
          </div>
          {/* Content */}
          <div className="grid gap-10 md:gap-12 lg:grid-cols-[400px_1fr]">
            {/* Main Content */}
            <div className="w-full max-w-[400px] mx-auto lg:mx-0">
              <img src={selectedImage} alt="" className="w-full h-auto" />
            </div>
            {/* Filters */}
            <div className="mb-4 max-w-none lg:max-w-lg">
              {/* Removed the form from here */}
              {/* Special Option (mainSeq === 48) */}
              {specialMainOption && (
                  <div key={specialMainOption.seq} className="flex flex-col gap-6">
                    <div className="flex items-center justify-between py-4">
                      <p className="font-semibold">{specialMainOption.name}</p>
                    </div>

                    <div className="flex flex-wrap gap-3">
                      {subOptions[specialMainOption.seq]?.map((subOption) => (
                        <button
                          key={subOption.seq}
                          className={`flex items-center justify-center gap-2 p-4 font-semibold rounded-lg text-base w-[30%] h-[60px] ${
                            formDetails[specialMainOption.name] === subOption.value
                              ? 'bg-black text-white'
                              : 'bg-[#f2f2f7]'
                          }`}
                          type="button"
                          name={specialMainOption.name}
                          value={subOption.value}
                          onClick={() => handleOptionClick(specialMainOption.name, subOption.value)}
                        >
                          {subOption.value}
                        </button>
                      ))}
                    </div>
                    <div className="mb-6 mt-6 h-px w-full bg-[#d9d9d9]"></div>
                  </div>
                )}

              {/* Other Options (Select Boxes) */}
              {otherMainOptions.map((mainOption) => (
                <div key={mainOption.seq} className="space-y-3">
                  <label className="block text-gray-800 font-semibold mb-1">{mainOption.name}</label>
                  <select
                    className="border rounded-md p-2 w-full"
                    name={mainOption.name}
                    value={formDetails[mainOption.name] || ''}
                    onChange={(e) => handleOptionClick(mainOption.name, e.target.value)}
                  >
                    {subOptions[mainOption.seq]?.map((subOption) => (
                      <option key={subOption.seq} value={subOption.value}>
                        {subOption.value}
                      </option>
                    ))}
                  </select>
                  {/* Divider */}
                  <div className="mb-6 mt-6 h-px w-full bg-[#d9d9d9]"></div>
                </div>
              ))}

              {/* Place Order Button */}
              <button
                type="button"
                className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 w-full mt-4"
                onClick={handleOrderSubmit}
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Form Modal */}
      {showFormModal && (
        <div
          className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-50 bg-opacity-50 bg-gray-800 overflow-auto font-sans"
          onClick={closeFormModal}
        >
          <div
            className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center">
              <h3 className="text-blue-600 text-xl font-bold flex-1">문의하기</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
                viewBox="0 0 320.591 320.591"
                onClick={closeFormModal}
              >
                <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
                <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
              </svg>
            </div>

            {/* Form */}
            <form className="space-y-4 mt-8" onSubmit={handleSubmit}>
              {/* Moved the form inputs here */}
              {/* Filters title */}
              <h5 className="text-xl font-bold">주문서</h5>

              {/* Name */}
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {/* Title */}
              <input
                type="text"
                name="title"
                placeholder="Title"
                className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                value={formData.title}
                onChange={handleChange}
                required
              />
              {/* Phone */}
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                className="px-4 py-3 bg-gray-100 w-full text-gray-800 text-sm border-none focus:outline-blue-600 focus:bg-white rounded-lg"
                value={formData.phone}
                onChange={handleChange}
                required
              />

              <div className="flex justify-end gap-4 mt-8">
                <button
                  type="button"
                  onClick={closeFormModal}
                  className="px-6 py-3 rounded-lg text-gray-800 text-sm bg-gray-200 hover:bg-gray-300"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-6 py-3 rounded-lg text-white text-sm bg-blue-600 hover:bg-blue-700"
                >
                  제출
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div
          className="fixed inset-0 p-4 flex justify-center items-center w-full h-full z-50 bg-opacity-50 bg-black overflow-auto font-sans"
          onClick={closeSuccessModal}
        >
          <div
            className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3.5 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500 float-right"
              viewBox="0 0 320.591 320.591"
              onClick={closeSuccessModal}
            >
              <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
              <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
            </svg>

            <div className="my-8 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-14 shrink-0 fill-green-500 inline"
                viewBox="0 0 512 512"
              >
                <path d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z" />
                <path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z" />
              </svg>
              <h4 className="text-xl text-gray-800 font-semibold mt-4">성공적으로 제출되었습니다!</h4>
              <p className="text-sm text-gray-500 leading-relaxed mt-4">
                저희에게 연락해주셔서 감사합니다. 곧 연락드리겠습니다.
              </p>
            </div>

            <button
              type="button"
              onClick={closeSuccessModal}
              className="px-5 py-2.5 w-full rounded-lg text-white text-sm bg-gray-800 hover:bg-gray-700"
            >
              확인
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Custom;
