import React, { useState, useEffect } from 'react';

const FAQ = ({ parentSeq = 229, useLocalData = false }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [error, setError] = useState(null);

  // Fetch FAQs from the API
  const fetchFaqsFromAPI = async () => {
    try {
      const response = await fetch(`/api/faqs/all?seq=${parentSeq}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
      });
      const data = await response.json();
      if (data.result === 'success') {
        setFaqs(data.rows); // Set faqs with data.rows array from API response
        setError(null);     // Clear any previous error
      } else {
        throw new Error('Failed to load FAQs');
      }
    } catch (error) {
      console.error("Error fetching FAQs from API:", error);
      setFaqs([]);
      setError("Failed to load FAQs");
    }
  };

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    if (useLocalData) {
      // Load FAQs from local data (optional if needed for testing)
      fetchFaqsFromAPI();
    } else {
      // Load FAQs from API
      fetchFaqsFromAPI();
    }
  }, [useLocalData, parentSeq]);  // Refetch FAQs if useLocalData or parentSeq changes

  return (
    <section className="py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-16">
          <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
            Q&A
          </h2>
        </div>
        <div className="accordion-group">
          {error ? (
            <p className="text-red-500">{error}</p>
          ) : faqs.length > 0 ? (
            faqs.map((faq, index) => (
              <div
                key={faq.seq}
                className={`accordion p-4 rounded-xl transition duration-500 ${
                  activeIndex === index
                    ? 'accordion-active:bg-indigo-50 accordion-active:border-indigo-600'
                    : ''
                } mb-8 lg:p-4`}
              >
                <button
                  className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-gray-600"
                  aria-controls={`collapse-${faq.seq}`}
                  onClick={() => handleQuestionClick(index)}
                >
                  <h5>{faq.title}</h5>
                  <svg
                    className={`w-6 h-6 text-gray-900 transition duration-500 ${
                      activeIndex === index
                        ? 'hidden'
                        : 'block group-hover:text-gray-600'
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 12H18M12 18V6"
                      stroke="currentColor"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <svg
                    className={`w-6 h-6 text-gray-900 transition duration-500 ${
                      activeIndex === index ? 'block' : 'hidden'
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 12H18"
                      stroke="currentColor"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div
                  id={`collapse-${faq.seq}`}
                  className={`accordion-content w-full overflow-hidden pr-4 transition-all duration-500 ${
                    activeIndex === index ? 'max-h-[250px]' : 'max-h-0'
                  }`}
                  aria-labelledby={`heading-${faq.seq}`}
                >
                  <p className="text-base text-gray-900 font-normal leading-6">
                    {faq.contents}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No FAQs available</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
