import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // 스타일 파일
import App from './App'; // App 컴포넌트 임포트
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App /> {/* App 컴포넌트 렌더링 */}
  </React.StrictMode>
);

reportWebVitals(); // 성능 측정 도구 (선택 사항)
