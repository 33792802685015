import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Detail = () => {
  const { productId } = useParams(); // Get the product ID from the URL
  const [product, setProduct] = useState(null); // Store the product data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch product data by making a POST request
    const fetchProductDetails = async () => {
      try {
        const response = await fetch( `/api/product/detail/${productId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
        });
        const data = await response.json();
        setProduct(data); // Set the product data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product details:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error occurred: {error.message}</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <section className="relative py-16">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mx-auto max-md:px-2">
          {/* Image Section */}
          <div className="img">
            <div className="img-box h-full max-lg:mx-auto">
              <img
                src={product.image400 || 'https://via.placeholder.com/1300'}
                alt={product.name}
                className="max-lg:mx-auto lg:ml-auto h-full object-cover"
              />
            </div>
          </div>

          {/* Product Information Section */}
          <div className="data w-full lg:pr-8 pr-0 xl:justify-start justify-center flex items-center max-lg:pb-10 xl:my-2 lg:my-5 my-0">
            <div className="data w-full max-w-xl">
              <h2 className="font-bold text-3xl leading-10 text-gray-900 mb-2">
                {product.name}
              </h2>

              {/* Price and Description */}
              <div className="flex flex-col sm:flex-row sm:items-center mb-6">
                <h6 className="font-semibold text-2xl text-gray-900 pr-5 sm:border-r border-gray-200 mr-5">
                  {product.price ?  `${product.price}원 `: 'Price not available'}
                </h6>
              </div>

              <p className="text-gray-500 text-base font-normal mb-5">
                {product.description || 'Description not available'}{''}
                <a href="#" className="text-gray-600">More info</a>
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Detail;
